import AttrPlugin from 'gsap/AttrPlugin';
import React from 'react';
const styles = require('./node.css');
import classnames from 'classnames';
import {
  INode,
} from '../../workerStore/rings/Utils';
import {
  decideColorForNode,
} from './Utils';

// Need to do this so that `AttrPlugin` is not dropped by the minifier:
/* tslint:disable-next-line:no-unused-expression */
AttrPlugin;

interface IProps {
  node: INode;
  connectedToHoveredNode: Set<number> | undefined;
  onNodeClick: (id: number) => void;
  onMouseEnter: (id: number) => void;
  onMouseLeave: () => void;
}

export default class extends React.PureComponent<IProps> {
  private onClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    const {node: {id}, onNodeClick} = this.props;
    onNodeClick(id);
  }
  private onMouseEnter = () => {
    const {node: {id}, onMouseEnter} = this.props;
    onMouseEnter(id);
  }
  private onMouseLeave = () => {
    this.props.onMouseLeave();
  }
  render() {
    const {
      node: {x, y, radius, color, depth, isActive, isCurrentlyExported, id},
      connectedToHoveredNode,
    } = this.props;
    const transform = `translate(${x} ${y}) scale(${radius})`;
    const className = classnames(styles.node, {
      [styles.centerNode]: (depth === 0),
    });
    const fill = decideColorForNode(
      id, connectedToHoveredNode, color, isActive, isCurrentlyExported,
    );

    return (
      <circle
        className={className} transform={transform} fill={fill}
        vectorEffect='non-scaling-stroke'
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        cx={0} cy={0} r={1}/>
    );
  }
}
