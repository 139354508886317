import {
  ProductClass,
} from '../../graphQL/types/shared';
import getReducer, {
  IBaseState,
} from '../../sharedComponents/getNewDataCache';
import {
  apiBaseURL,
  fetchJSON,
  ProductMetadatumLevel,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

export enum MappingType {
  // 4-digit to 1-digit:
  Hi2Low = 'Hi2Low',
  // 4-digit to 2-digit:
  Hi2Mid = 'Hi2Mid',
}
// Mapping from 4-digit-level product ID to section-level ID:
interface IAPIResponse {
  data: {
    [fourDigitId: string]: number;
  };
}
export type IMappingData = Map<number, number>;

const getDataFromAPIResponse = ({data}: IAPIResponse) => {
  const pairs: Array<[number, number]> = Object.entries(data).map(
    ([key, value]) => ([parseInt(key, 10), value] as [number, number]),
  );
  return new Map(pairs);
};
export type IState = IBaseState<IMappingData>;

const FETCH_BEGIN = 'PRODUCT_LEVEL_MAPPING_FETCH_BEGIN';
const FETCH_SUCCESS = 'PRODUCT_LEVEL_MAPPING_FETCH_SUCCESS';
const FETCH_FAIL = 'PRODUCT_LEVEL_MAPPING_FETCH_FAIL';
const FETCH_IF_NEEDED = 'PRODUCT_LEVEL_MAPPING_FETCH_IF_NEEDED';

type IHash = {productClass: ProductClass, mappingType: MappingType};

const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  getDataSelector,
} = getReducer<
  IMappingData,
  IWorkerRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataCache: (rootState: IWorkerRootState) => rootState.productLevelMapping,
  getFetchPromise: ({productClass, mappingType}: IHash) => {
    const productClassPhrase = (productClass === ProductClass.HS) ? 'hs_product' : 'sitc_product';
    let fromLevel: string, toLevel: string;
    if (mappingType === MappingType.Hi2Low) {
      fromLevel = ProductMetadatumLevel.fourDigit;
      toLevel = ProductMetadatumLevel.section;
    } else {
      fromLevel = ProductMetadatumLevel.fourDigit;
      toLevel = ProductMetadatumLevel.twoDigit;
    }
    const url = `${apiBaseURL}/metadata/${productClassPhrase}/hierarchy?from_level=${fromLevel}&to_level=${toLevel}`;
    return fetchJSON<IAPIResponse>(url);
  },
  hashFunction: ({productClass, mappingType}: IHash) => `${productClass}_${mappingType}`,
  getDataFromAPIResponse,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, getDataSelector};
