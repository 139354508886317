import React from 'react';
import styled from 'styled-components';

import {NewRegionColor} from "../../Utils";

interface Category {
  name: string;
  color: string;
  icon: string | undefined;
}

interface CategoryMap {
  [key: string]: Category;
}

const categoryMap: CategoryMap = {
  'product-HS-0': {
    name: 'Textiles',
    color: 'rgb(125, 218, 161)',
    icon: 'product-HS-0',
  },
  'product-HS-1': {
    name: 'Agriculture',
    color: '#F5CF23',
    icon: 'product-HS-1',
  },
  'product-HS-2': {
    name: 'Stone',
    color: 'rgb(218, 180, 125)',
    icon: 'product-HS-2',
  },
  'product-HS-3': {
    name: 'Minerals',
    color: 'rgb(187, 150, 138)',
    icon: 'product-HS-3',
  },
  'product-HS-4': {
    name: 'Metals',
    color: 'rgb(217, 123, 123)',
    icon: 'product-HS-4',
  },
  'product-HS-5': {
    name: 'Chemicals',
    color: 'rgb(197, 123, 217)',
    icon: 'product-HS-5',
  },
  'product-HS-6': {
    name: 'Vehicles',
    color: 'rgb(141, 123, 216)',
    icon: 'product-HS-6',
  },
  'product-HS-7': {
    name: 'Machinery',
    color: 'rgb(123, 162, 217)',
    icon: 'product-HS-7',
  },
  'product-HS-8': {
    name: 'Electronics',
    color: 'rgb(125, 218, 218)',
    icon: 'product-HS-8',
  },
  'product-HS-9': {
    name: 'Other',
    color: '#2a607c',
    icon: 'product-HS-9',
  },
  'product-HS-10': {
    name: 'Services',
    color: 'rgb(178, 61, 109)',
    icon: 'product-HS-10',
  },
  'product-SITC-0': {
    name: 'Food',
    color: '#F5CF23',
    icon: 'product-SITC-0',
  },
  'product-SITC-1': {
    name: 'Beverages',
    color: '#FE85AD',
    icon: 'product-SITC-1',
  },
  'product-SITC-2': {
    name: 'Crude Materials',
    color: 'rgb(217, 123, 123)',
    icon: 'product-SITC-2',
  },
  'product-SITC-3': {
    name: 'Fuels',
    color: 'rgb(187, 150, 138)',
    icon: 'product-SITC-3',
  },
  'product-SITC-4': {
    name: 'Vegetable Oils',
    color: '#FDA81B',
    icon: 'product-SITC-4',
  },
  'product-SITC-5': {
    name: 'Chemicals',
    color: 'rgb(197, 123, 217)',
    icon: 'product-SITC-5',
  },
  'product-SITC-6': {
    name: 'Material Manufacturers',
    color: '#DA3329',
    icon: 'product-SITC-6',
  },
  'product-SITC-7': {
    name: 'Machinery and Vehicles',
    color: 'rgb(123, 162, 217)',
    icon: 'product-SITC-7',
  },
  'product-SITC-8': {
    name: 'Other Manufacturers',
    color: '#00A6AA',
    icon: 'product-SITC-8',
  },
  'product-SITC-9': {
    name: 'Unspecified',
    color: '#2a607c',
    icon: 'product-SITC-9',
  },
  'product-SITC-10': {
    name: 'Services',
    color: 'rgb(178, 61, 109)',
    icon: 'product-SITC-10',
  },
  'group-1': {
    name: 'Africa',
    color: NewRegionColor.Africa,
    icon: undefined,
  },
  'group-3': {
    name: 'Asia',
    color: NewRegionColor.Asia,
    icon: undefined,
  },
  'group-5': {
    name: 'Oceania',
    color: NewRegionColor.Oceania,
    icon: undefined,
  },
  'group-4': {
    name: 'Europe',
    color: NewRegionColor.Europe,
    icon: undefined,
  },
  'group-2': {
    name: 'Americas',
    color: NewRegionColor.Americas,
    icon: undefined,
  },
  'group-6': {
    name: 'Other',
    color: NewRegionColor.Other,
    icon: undefined,
  },
};

const Icon = styled.div<{color: string}>`
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.25rem;

  svg {
    width: 0.8rem;
    height: 0.8rem;
    fill: ${({color}) => color};

    path,
    rect {
      fill: ${({color}) => color};
    }
  }
`;

const Label = styled.div`
  font-weight: 600;
  display: inline-flex;
  align-items: flex-start;

  &:last-of-type {
    margin-right: 0.25rem;
  }
`;

const HiddenRegionMarker = styled.div<{color: string}>`
  display: inline-block;
  align-self: center;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border: 1px solid #FFFFFF;
  background-color: ${({color}) => color};
`;

interface Props {
  id: string;
}

const HiddenItem = (props: Props) => {
  const {id, regionOrSector} = props;
  const { name, icon, color } = categoryMap[id];
  const image = icon === undefined ? null : (
    <Icon
      color={color}
      dangerouslySetInnerHTML={{__html: require(`../../img/icons/categories_new/${icon}.svg`)}}
    />
  );
  let labelToUse;
  if(regionOrSector === "region") {
    labelToUse = (<Label><HiddenRegionMarker color={color} /> {name} data</Label>);
  } else {
    labelToUse = (<Label style={{color}}>{image} {name} data</Label>);
  }
  return labelToUse;
};

export default HiddenItem;
