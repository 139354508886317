import styled, {
  css,
} from 'styled-components';

const gridLines = {

  firstLabelTop: 'singleColumnFirstLabelTop',
  firstLabelBottom: 'singleColumnfirstLabelBottom',
  firstControlTop: 'singleColumnfirstControlTop',
  firstControlBottom: 'singleColumnfirstControlBottom',

  secondLabelTop: 'singleColumnsecondLabelTop',
  secondLabelBottom: 'singleColumnsecondLabelBottom',
  secondControlTop: 'singleColumnsecondControlTop',
  secondControlBottom: 'singleColumnsecondControlBottom',
};

export const controlsLabelStyle = css`
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.7rem;
`;

export const ItemContainerBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const LeftUppertLabelContainer = styled(ItemContainerBase)`
  ${controlsLabelStyle}
  grid-row: ${gridLines.firstLabelTop} / ${gridLines.firstLabelBottom};
  white-space: nowrap;
`;

export const LeftUpperControlContainer = styled(ItemContainerBase)`
  grid-row: ${gridLines.firstControlTop} / ${gridLines.firstControlBottom};
`;

export const RightUpperLabelContainer = styled(ItemContainerBase)`
  ${controlsLabelStyle}
  grid-row: ${gridLines.secondLabelTop} / ${gridLines.secondLabelBottom};
  white-space: nowrap;
`;

export const RightUpperControlContainer = styled(ItemContainerBase)`
  grid-row: ${gridLines.secondControlTop} / ${gridLines.secondControlBottom};
`;

const secondRowStyle = css`
  grid-column: 1;
`;
export const LeftLowerLabelContainer = styled(LeftUppertLabelContainer)`
  ${controlsLabelStyle}
  ${secondRowStyle}
`;
export const LeftLowerControlContainer = styled(LeftUpperControlContainer)`
  ${secondRowStyle}
`;
export const RightLowerLabelContainer = styled(RightUpperLabelContainer)`
  ${controlsLabelStyle}
  ${secondRowStyle}
`;
export const RightLowerControlContainer = styled(RightUpperControlContainer)`
  ${secondRowStyle}
`;

export const rowHeight = '2rem';
export const gridRowGap = '2rem';
