import React from 'react';
import styled from 'styled-components';
import { QueryLevel } from '../../graphQL/types/shared';
import {
  formatTradeValue,
} from '../../numberFormatters';
import Tooltip from '../../viz/Tooltip';
import { exploreGray } from '../../viz/VizGrid';
import HiddenItem from './HiddenItem';

//#region Styling
const Root = styled.div`
  font-size: 1rem;
  color: ${exploreGray};
  font-weight: 400;
`;
const ContentWord = styled.div`
  display: inline-block;
  padding: 0 0.3rem;

  &:nth-child(3) {
    border-left: 1px solid ${exploreGray};
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

const HiddenTotalLabel = styled.div`
  display: inline-block;
  padding: 0 0.3rem;

  @media (min-width: 1200px) {
    display: none;
  }
`;

const Section = styled.div`
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: solid 1px #fff;
`;
const HiddenItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
`;
const Comma = styled.span`
  display: inline-block;
  margin-right: 0.25rem;
`;
const And = styled.span`
  display: inline-block;
  margin: 0 0.25rem;
`;
const SemiBold = styled.span`
  font-weight: 600;
`;
const GoodsAndServicesTable = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  padding: 0.4rem 0;
`;
const ServicesLabel = styled.span`
  grid-column: 1;
  grid-row: 1;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
  padding-right: 0.5rem;
  padding-bottom: 0.3rem;
`;
const GoodsLabel = styled.span`
  grid-column: 1;
  grid-row: 2;
  border-right: solid 1px #fff;
  padding-right: 0.5rem;
  padding-top: 0.3rem;
`;
const ServicesValue = styled.span`
  grid-column: 2;
  grid-row: 1;
  border-bottom: solid 1px #fff;
  padding: 0 0.5rem 0.3rem;
`;
const GoodsValue = styled.span`
  grid-column: 2;
  grid-row: 2;
  padding: 0.3rem 0.5rem 0;
`;
const ServicesNote = styled.div`
  margin-bottom: 0.5rem;
`;

const ShownTotalDifferenceDisclaimer = styled.div`
  font-style: italic;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

//#endregion

interface Props {
  numerator: number;
  denominator: number | undefined;
  totalGoods: number;
  totalServices: number | null | undefined;
  hiddenCategories: string[];
  selectedCategories: string[];
  complexityGraph?: boolean;
}

const GraphTotal = (props: Props) => {
  const {
    numerator, denominator, totalGoods, totalServices, hiddenCategories, selectedCategories,
    complexityGraph, queryLevel, locationLevel
  } = props;


  const formattedNumerator = formatTradeValue(numerator);
  let shownText: string;
  let denominatorElem: React.ReactElement<any> | null;
  if (denominator === undefined) {
    shownText = 'Total/Shown';
    denominatorElem = null;
  } else {
    shownText = 'Shown';
    const formattedDenominator = formatTradeValue(denominator);
    denominatorElem = (
      <>
        <ContentWord>{__lexiconText('totalAboveGraph.denominator')}</ContentWord>
        <ContentWord>{formattedDenominator}</ContentWord>
      </>
    );
  }

  let goodsAndServices: React.ReactElement<any> | null;
  if (totalServices === undefined) {
    // then it must be a product view. Don't show goods or sevices
    goodsAndServices = null;
  } else if (totalServices === null) {
    // then it must be a view where services are not reported
    // show totalServices as N/A and display special services data message
    const servicesNoteSentence2 = complexityGraph === true ? null : (
      <>
        Countries report their services exports and imports <SemiBold>unilaterally</SemiBold>.
      </>
    );
    goodsAndServices = (
      <>
        <Section>
          <GoodsAndServicesTable>
            <ServicesLabel>Total Services:</ServicesLabel>
            <ServicesValue>N/A</ServicesValue>
            <GoodsLabel>Total Goods:</GoodsLabel>
            <GoodsValue>{formatTradeValue(totalGoods)}</GoodsValue>
          </GoodsAndServicesTable>
        </Section>
        <ServicesNote>
          <HiddenItem id={'product-HS-10'} /> is <SemiBold>not</SemiBold> available in this visualization.
          {' '}
          {servicesNoteSentence2}
        </ServicesNote>
      </>
    );
  } else { //totalServices is a number
    // show both goods and services
    goodsAndServices = (
      <Section>
        <GoodsAndServicesTable>
          <ServicesLabel>Total Services:</ServicesLabel>
          <ServicesValue>{formatTradeValue(totalServices)}</ServicesValue>
          <GoodsLabel>Total Goods:</GoodsLabel>
          <GoodsValue>{formatTradeValue(totalGoods)}</GoodsValue>
        </GoodsAndServicesTable>
      </Section>
    );
  }

  const regionOrSector = selectedCategories.length && selectedCategories[0].includes('group')
    ? 'region' : 'sector';

  let hiddenItems: React.ReactNode;
  if (!selectedCategories.length && !hiddenCategories.length) {
    hiddenItems = null;
  } else {
    hiddenItems = hiddenCategories.length ? hiddenCategories.map((id, i) => {
      let seperator: React.ReactElement<any> | null;
      if (hiddenCategories.length === 1) {
        seperator = null;
      } else if (hiddenCategories.length === 2) {
        seperator = i === 0 ? <And>and</And> : null;
      } else {
        if (i === hiddenCategories.length - 2) {
          seperator = <And>and</And>;
        } else if (i === hiddenCategories.length - 1) {
          seperator = null;
        } else {
          seperator = <Comma>,</Comma>;
        }
      }
      return (
        <React.Fragment key={id}>
          <HiddenItem id={id} regionOrSector={regionOrSector}/>
          {seperator}
        </React.Fragment>
      );
    }) : <em>Currently, no {regionOrSector} is hidden</em>;
  }

  let dataHiddenNote: string;
  if (hiddenCategories.length === 1) {
    dataHiddenNote = ' is hidden';
  } else if (hiddenCategories.length > 1) {
    dataHiddenNote = ' are hidden';
  } else {
    dataHiddenNote = '';
  }
  let displayTextForTotalValueShown = "Total volume for country"; // Default value in most cases
  if(queryLevel === QueryLevel.Location) {
    displayTextForTotalValueShown = "Total volume for country";
  } else if(queryLevel === QueryLevel.Group) {
    displayTextForTotalValueShown = "Total volume for group of countries"
  } else {
    // If queryLevel is undefined, we are in Product view, and
    // need to determine if user has selected country, subregion, or region detail level
    if(locationLevel === "country") {
      displayTextForTotalValueShown = "Total volume for country";
    } else if(locationLevel === "subregion" || locationLevel === "region") {
      displayTextForTotalValueShown = "Total volume for group of countries";
    } else {
      // In visualization where locationLevel can't be defined, e.g., geomap,
      // use default text value
      displayTextForTotalValueShown = "Total volume for country";
    }

  }


  return (
    <Root>
      <ContentWord>{shownText}:</ContentWord>
      <ContentWord>{formattedNumerator}</ContentWord>
      {denominatorElem}
      <HiddenTotalLabel>Total</HiddenTotalLabel>
      <Tooltip explanation={
        <>
          <Section
            style={{
              borderBottom: totalServices === undefined && !selectedCategories.length && !hiddenCategories.length
                ? 'none' : undefined,
              marginBottom: totalServices === undefined && !selectedCategories.length && !hiddenCategories.length
                ? '0' : undefined,
              paddingBottom: totalServices === undefined && !selectedCategories.length && !hiddenCategories.length
                ? '0' : undefined,
            }}
          >
            <SemiBold>Shown:</SemiBold> Visible in the visualization
            <br />
            <SemiBold>Total:</SemiBold> {displayTextForTotalValueShown}
            <br />
            <ShownTotalDifferenceDisclaimer>Shown and total values may differ due to trade with undeclared partners</ShownTotalDifferenceDisclaimer>
          </Section>
          {goodsAndServices}
          <HiddenItemsContainer>
            {hiddenItems}
            {dataHiddenNote}
          </HiddenItemsContainer>
        </>
      } />
    </Root>
  );
};

export default GraphTotal;
