import flatten from 'lodash-es/flatten';
import React from 'react';
import {
  ITextInCircle,
  ITextInRectangle,
  LayoutText,
  TextType,
} from '../../workerStore/rings/Utils';
const styles = require('./nodeLabel.css');
import {
  centerNodeTextColor,
} from '../Utils';

export const getLabelInRectangleGetter =
  (chartContainerWidth: number, chartContainerHeight: number, className: string) =>
    ({text}: ITextInRectangle,
     id: number): JSX.Element | null => {

  if (text.showText === true) {
    const {
      centerX, centerY, angle, width, height, textUnsplit, fontSize, color,
    } = text;

    // Need to adjust the angle so that texts don't appear upside down
    // while rotated: This happens when the rotation is between 90deg and
    // 270deg. The adjustment doesn't change the label's position and
    // apparent rotation but changes the rotation of text:
    const adjustedAngle = (angle < 90 || angle > 270) ? angle : angle + 180;

    // Need this so that the node label is positioned radially closer to the node
    // it's labelling:
    let textAlign: 'left' | 'right';
    let justifyContent: 'flex-start' | 'flex-end';
    if (angle > 90 && angle < 270) {
      textAlign = 'right';
      justifyContent = 'flex-end';
    } else {
      textAlign = 'left';
      justifyContent = 'flex-start';
    }

    const style = {
      '--translation-x': `${centerX}px`,
      '--translation-y': `${centerY}px`,
      '--x-center': `${chartContainerWidth / 2}px`,
      '--y-center': `${chartContainerHeight / 2}px`,
      '--rotation': `${adjustedAngle}deg`,
      '--width': `${width}px`,
      '--height': `${height}px`,
      '--font-size': `${fontSize}px`,
      '--color': color,
      textAlign,
      justifyContent,
    };
    return (
      <div className={className} style={style} key={id}>
        {textUnsplit}
      </div>

    );
  } else {
    return null;
  }
};

// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--translation-x'?: string;
    '--translation-y'?: string;
    '--x-center'?: string;
    '--y-center'?: string;
    '--height'?: string;
    '--width'?: string;
    '--font-size'?: string;
    '--color'?: string;
  }
}
export const getLabelInCircleGetter = (
  chartContainerWidth: number, chartContainerHeight: number, className: string) =>
    ({text}: ITextInCircle, id: number): JSX.Element | null => {

  if (text.showText === true) {
      const {centerX, centerY, fontSize, textSplitIntoLines, radius} = text;
      const unflattenedLines = textSplitIntoLines.map((lineText, index) => {
        const actualText = (
          <span key={`text-${index}`}>{lineText}</span>
        );
        // Insert line breaks between lines:
        if (index === 0) {
          return [actualText];
        } else {
          return [
            <br key={`line-break-${index}`}/>,
            actualText,
          ];
        }
      });
      const lines = flatten(unflattenedLines);
      const size = 2 * radius;
      const style: React.CSSProperties = {
        '--translation-x': `${centerX}px`,
        '--translation-y': `${centerY}px`,
        '--x-center': `${chartContainerWidth / 2}px`,
        '--y-center': `${chartContainerHeight / 2}px`,
        '--width': `${size}px`,
        '--height': `${size}px`,
        '--font-size': `${fontSize}px`,
        '--color': centerNodeTextColor,
        'textAlign': 'center',
      };
      return (
        <div className={className} style={style} key={id}>
          <p>
            {lines}
          </p>
        </div>
      );

  } else {
    return null;
  }
};
interface IProps {
  texts: LayoutText[];
  connectedToHoveredNode: Set<number> | undefined;
  chartContainerWidth: number | undefined;
  chartContainerHeight: number | undefined;
}

export default class extends React.PureComponent<IProps> {
  render() {
    const {texts, chartContainerHeight, chartContainerWidth} = this.props;
    if (chartContainerWidth === undefined || chartContainerHeight === undefined) {
      return null;
    } else {
      const getLabelInRectangle = getLabelInRectangleGetter(
        chartContainerWidth, chartContainerHeight, styles.label,
      );
      const getLabelInCircle = getLabelInCircleGetter(
        chartContainerWidth, chartContainerHeight, styles.label,
      );
      const labels: Array<JSX.Element | null> = texts.map(datum => {
        if (datum.type === TextType.InRectangle) {
          return getLabelInRectangle(
            datum, datum.id,
          );
        } else {
          return getLabelInCircle(
            datum, datum.id,
          );
        }
      });
      return labels as any;
    }
  }
}
