import React, {useEffect, useRef, useState} from 'react';
import {
  ReactSelectProps,
} from 'react-select';
import {
  Subscription,
} from 'rxjs';
import styled from 'styled-components';
import { smallMediaQuery } from '../countryProfiles/Grid';
import { secondaryGray } from '../cssVariables';
import Tooltip, {
  defaultTooltipDelay,
  getTooltipVisibilityStream,
} from '../sharedComponents/UITooltip';
import {
  highlightDropdownFilterFunction,
  INewDropdownOption,
} from '../Utils';

export const Root = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const buttonSize = 26; // in px

export const SelectButton = styled.div`
  pointer-events: none;
  position: absolute;
  right: 0;
  width: ${buttonSize}px;
  height: ${buttonSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${secondaryGray};
  color: #fff;
  border-radius: 1000px;
  cursor: pointer;
  bottom: 0;

  svg {
    position: absolute;
    width: 16px;
    fill: white;
  }
`;

export const Label = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  margin: auto 0;
  display: flex;
  justify-content: flex-end;
  color: #666;
  text-align: right;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  right: ${buttonSize + 4}px;

  @media ${smallMediaQuery} {
    font-size: 0.6rem;
  }
`;

type DisplayedValue = {
  isClearable: true;
  value: string | undefined;
} | {
  isClearable: false;
  value: string;
};

export type IProps = {
  label: string;
  tooltipText: string;
  options: INewDropdownOption[];
  onChange: (input: INewDropdownOption | null) => void;
  // `DropdownComponent` is something like `styled(Select)`stome style``:
  DropdownComponent: React.ComponentClass<ReactSelectProps<any>, any>;
  DropdownContainerComponent: any;
  optionRenderer?: (option: INewDropdownOption) => JSX.Element;
  valueRenderer?: (option: INewDropdownOption) => JSX.Element;
} & DisplayedValue;

export default (props: IProps) => {
  const {
    label, options, value, isClearable, tooltipText,
    DropdownComponent, DropdownContainerComponent,
    onChange, optionRenderer, valueRenderer,
  } = props;

  const [tooltipShown, setTooltipShown] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const dropdownContainerEl = useRef<HTMLElement | null>(null);
  let showOrHideSubscription: Subscription | undefined;

  useEffect(() => {
    if (dropdownContainerEl !== null) {
      if (dropdownContainerEl && dropdownContainerEl.current) {
        const showOrHide$ = getTooltipVisibilityStream(dropdownContainerEl.current, defaultTooltipDelay);
        showOrHideSubscription = showOrHide$.subscribe(isTooltipShown =>
          setTooltipShown(isTooltipShown),
        );
      }
    }
    return () => {
      if (showOrHideSubscription !== undefined) {
        showOrHideSubscription.unsubscribe();
      }
    };
  }, [dropdownContainerEl]);

  const tooltip =  tooltipShown ? (
    <Tooltip>{tooltipText}</Tooltip>
  ) : null;

  const style: React.CSSProperties = isMenuOpen === false && value === undefined ? {
    opacity: 0,
  } : {
    opacity: 1,
    width: 300,
  };

  return (
    <>
      <Label>{label}</Label>
      <SelectButton
        dangerouslySetInnerHTML={{__html: require('../countryProfiles/countrySelector/countrySearch.svg')}}
      />
      <DropdownContainerComponent ref={dropdownContainerEl} style={{position: 'absolute', bottom: 0, right: 0}}>
        <Root style={style}>
          <DropdownComponent
            options={options} value={value} onChange={onChange}
            optionRenderer={optionRenderer} valueRenderer={valueRenderer}
            clearable={isClearable} filterOption={highlightDropdownFilterFunction}
            onOpen={() => setIsMenuOpen(true)}
            onClose={() => setIsMenuOpen(false)}
            menuStyle={{maxHeight: 80}}
          />
          {tooltip}
        </Root>
      </DropdownContainerComponent>
    </>
  );
};
