import flatten from 'lodash-es/flatten';
import sortBy from 'lodash-es/sortBy';
import React from 'react';
import styled from 'styled-components';
import {
  ProductClass,
} from '../graphQL/types/shared';
import {
  H4,
} from './TextStyling';

const Connections = styled.div`
  display: grid;
  grid-template-columns: 1fr 22fr;
  grid-gap: 1.3vh 2%;
`;

const IconContainer = styled.div`
  grid-column: 1;
  display: flex;
  align-items: center;
`;

// `height` and `padding-top` are set so that the aspect ratio is 1:
const Icon = styled.div`
  border-radius: 50%;
  width: 100%;
  height: 0;
  padding-top: 100%;
`;

const Label = styled.div`
  grid-column: 2;
  display: flex;
  margin-left: 1vw;
  font-size: 0.8rem;
  line-height: 1.2;
`;

export interface IConnection {
  color: string;
  label: string;
  id: number;
  code: string;
}

interface IProps {
  connections: IConnection[];
  title: string;
  productClass: ProductClass;
}

export default class extends React.Component<IProps> {
  render() {
    const {connections, title, productClass} = this.props;
    const productClassPhrase = (productClass === ProductClass.HS) ?
                                __lexiconText('applicationWide.productClass.HS') :
                                __lexiconText('applicationWide.productClass.SITC');

    const sorted = sortBy(connections, ({code}) => code);
    const conectionElems = sorted.map(({color, label, code}) => {
      const nodeStyle = {
        backgroundColor: color,
      };

      return (
        <>
          <IconContainer>
            <Icon style={nodeStyle}/>
          </IconContainer>
          <Label>{productClassPhrase} {code}: {label}</Label>
        </>
      );
    });

    return (
      <div>
        <H4>{title}</H4>
        <Connections>
          {flatten(conectionElems)}
        </Connections>
      </div>
    );
  }
}
