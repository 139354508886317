import React from 'react';
import {
  ProductClass,
} from '../../graphQL/types/shared';
import DetailOverlay from '../../sharedComponents/DetailOverlay';
import Connections, {
  IConnection,
} from '../../sharedComponents/DetailOverlayConnections';
import {
  INode,
  ITooltipDatum,
} from '../../workerStore/rings/Utils';

interface IProps {
  detailed: number | undefined;
  nodes: INode[];
  tooltipMap: Record<string, ITooltipDatum>;
  hideOverlay: () => void;
  rememberEl: (el: HTMLElement | null) => void;
  productClass: ProductClass;
}

export default class extends React.Component<IProps> {
  render() {
    const {detailed, tooltipMap, hideOverlay, rememberEl, nodes, productClass} = this.props;

    if (detailed === undefined) {
      return null;
    } else {
      const retrieved = tooltipMap[detailed];
      if (retrieved === undefined) {
        return null;
      } else {
        const {color, longLabel, tooltipInfo} = retrieved;
        const getConnections = (node: INode) => {
          const {id, code} = node;
          const connection: IConnection = {
            label: tooltipMap[id].longLabel,
            color, id, code,
          };
          return connection;
        };
        const firstLevelConnections = nodes.filter(({depth}) => depth === 1).map(getConnections);
        const secondLevelConnections = nodes.filter(({depth}) => depth === 2).map(getConnections);

        return (
          <DetailOverlay rows={tooltipInfo} color={color} title={longLabel}
            hideOverlay={hideOverlay}
            rememberEl={rememberEl}>

            <Connections
              productClass={productClass}
              title={__lexiconText('applicationWide.detailOverlayConnections.primary')}
              connections={firstLevelConnections}/>

            <Connections
              productClass={productClass}
              title={__lexiconText('applicationWide.detailOverlayConnections.secondary')}
              connections={secondLevelConnections}/>
          </DetailOverlay>
        );
      }
    }
  }
}
