export const svgBackgroundColor = 'rgb(245,245,245)';

export const nodeChangeTransitionDuration = 350; // in milliseconds

export const deselectedCategoryColor = 'rgb(150, 150, 160)';

export const centerNodeTextColor = 'white';

export const defaultLinkStrokeColor = 'rgb(224, 224, 234)';

export const exportedFileName = 'Rings';
