import CSSPlugin from 'gsap/CSSPlugin';
import TweenLite from 'gsap/TweenLite';
import React from 'react';
import Transition, {
  TransitionProps,
} from 'react-transition-group/Transition';

// Need to do this so that `CSSPlugin` is not dropped by the minifier:
/* tslint:disable-next-line:no-unused-expression */
CSSPlugin;

const onEntering =
  (targetOpacity: number) =>
    (node: HTMLElement) =>
      TweenLite.set(node, {css: {opacity: targetOpacity}});

const onExiting =
    (node: HTMLElement) =>
      TweenLite.set(node, {css: {opacity: 0}});

interface IProps extends TransitionProps {
  timeout: number;
  normalOpacity?: number;
}

export default class extends React.PureComponent<IProps> {
  render() {
    const {
      timeout,
      children,
      normalOpacity = 1,
      ...rest} = this.props;
    return (
      <Transition {...rest}
        timeout={timeout}
        appear={true}
        onEntering={onEntering(normalOpacity)}
        onExiting={onExiting}>

        {children}

      </Transition>
    );
  }
}
