import {
  ProductClass,
} from '../graphQL/types/shared';
import {
  ColorQuadruplet,
  ColorTriplet,
} from '../sharedComponents/webglUtils';

export const gridLines = {
  pSpaceGraphTop: 'vizPSpaceGraphTop',
  pSpaceFilterTop: 'vizPSpaceFilterTop',
  pSpaceTimelineTop: 'vizPSpaceTimelineTop',
};

export const svgBackgroundColor = 'rgb(245,245,245)';

export const controlButtonZoomFactor = 1.1;

export const animationDuration = 350; // in milliseconds

// Node fill:
export const unexportedNodeBackgroundColor: ColorTriplet = [235, 235, 235];
export const deselectedNodeBackgroundColor: ColorTriplet = [150, 150, 160];

// Node stroke:
export const regularStrokeColor: ColorTriplet = [130, 130, 150];
export const hoveredNodeStrokeColor: ColorTriplet = [255, 255, 255];
export const relatedToHoveredNodeStrokeColor: ColorTriplet = [255, 0, 0];

// Node stroke width:
export const hoveredStrokeWidth = 4;
export const regularStrokeWidth = 1;
export const selectedOrHighlightedStrokeWidth = 4;
export const relatedToHoveredOrSelectedStrokeWidth = 4;

// Edge color:
export const relatedToSelectdOrHighlightedEdgeColor: ColorQuadruplet = [
  17, 17, 108, 255,
];
export const regularEdgeColor = [136, 136, 153, 64] as ColorQuadruplet;
export const relatedToHoveredEdgeColor = [255, 0, 0, 255] as ColorQuadruplet;

// Edge thickness:
export const relatedToHoveredEdgeWidth = 2;
export const relatedToSelectedHighlightedEdgeWidth = 2;
export const regularEdgeWidth = 1;

// Dash line config for hovered node:
export const relatedToHoveredSolidSegmentLength = 8;

export const getHiddenCategories = (sectorIds: number[], productClass: ProductClass) => {
  return sectorIds.map(id => `product-${productClass}-${id}`);
};

export const categoryMapHS = [
  {
    name: 'Textiles',
    color: 'rgb(125, 218, 161)',
    sector: 0,
  }, {
    name: 'Agriculture',
    color: '#F5CF23',
    sector: 1,
  }, {
    name: 'Stone',
    color: 'rgb(218, 180, 125)',
    sector: 2,
  }, {
    name: 'Minerals',
    color: 'rgb(187, 150, 138)',
    sector: 3,
  }, {
    name: 'Metals',
    color: 'rgb(217, 123, 123)',
    sector: 4,
  }, {
    name: 'Chemicals',
    color: 'rgb(197, 123, 217)',
    sector: 5,
  }, {
    name: 'Vehicles',
    color: 'rgb(141, 123, 216)',
    sector: 6,
  }, {
    name: 'Machinery',
    color: 'rgb(123, 162, 217)',
    sector: 7,
  }, {
    name: 'Electronics',
    color: 'rgb(125, 218, 218)',
    sector: 8,
  }, {
    name: 'Other',
    color: '#2a607c',
    sector: 9,
  }, {
    name: 'Services',
    color: 'rgb(178, 61, 109)',
    sector: 10,
  },
];
export const categoryMapSITC = [ {
    name: 'Food',
    color: '#F5CF23',
    sector: 0,
  }, {
    name: 'Beverages',
    color: '#FE85AD',
    sector: 1,
  }, {
    name: 'Crude Materials',
    color: 'rgb(217, 123, 123)',
    sector: 2,
  }, {
    name: 'Fuels',
    color: 'rgb(187, 150, 138)',
    sector: 3,
  }, {
    name: 'Vegetable Oils',
    color: '#FDA81B',
    sector: 4,
  }, {
    name: 'Chemicals',
    color: 'rgb(197, 123, 217)',
    sector: 5,
  }, {
    name: 'Material Manufacturers',
    color: '#DA3329',
    sector: 6,
  }, {
    name: 'Machinery and Vehicles',
    color: 'rgb(123, 162, 217)',
    sector: 7,
  }, {
    name: 'Other Manufacturers',
    color: '#00A6AA',
    sector: 8,
  }, {
    name: 'Unspecified',
    color: '#2a607c',
    sector: 9,
  }, {
    name: 'Services',
    color: 'rgb(178, 61, 109)',
    sector: 10,
  },
];
