import React from 'react';
import {
  ProductClass,
} from '../../graphQL/types/shared';
import {
  getIdentityTransformMatrix,
} from '../../network/panZoom';
import Tooltip from '../../sharedComponents/NetworkTooltip';
import {
  ITooltipDatum,
} from '../../workerStore/rings/Utils';

const identityTransformation = getIdentityTransformMatrix();

interface IProps {
  hovered: number;
  tooltipMap: Record<string, ITooltipDatum>;
  chartContainerWidth: number | undefined;
  chartContainerHeight: number | undefined;
  productClass: ProductClass;
}

export default class extends React.Component<IProps> {
  render() {
    const {
      hovered, tooltipMap, chartContainerHeight, chartContainerWidth,
      productClass,
    } = this.props;
    let result: JSX.Element | null;
    const retrieved = tooltipMap[hovered];
    if (retrieved === undefined || chartContainerHeight === undefined || chartContainerWidth === undefined) {
      result = null;
    } else {
      const {x, y, radius, shortLabel, code, level} = retrieved;
      // Need to translate by half the height and width because x and y are
      // relative to the center of the rings graph"
      const tooltipY = y - radius + chartContainerHeight / 2;
      const tooltipX = x + chartContainerWidth / 2;
      result = (
        <Tooltip
          svgWidth={chartContainerWidth}
          svgHeight={chartContainerHeight}
          transformationMatrix={identityTransformation}
          productName={shortLabel}
          productCode={code}
          productLevel={level}
          productClass={productClass}
          xOffset={tooltipX}
          yOffset={tooltipY}
        />
      );
    }
    return result;
  }
}
