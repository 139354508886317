import {
  desaturate,
  lighten,
} from 'polished';
import {
  deselectedCategoryColor,
} from '../Utils';

export const decideColorForNode = (
    nodeId: number,
    connectedToHoveredNode: Set<number> | undefined,
    normalColor: string,
    isActive: boolean,
    isCurrentlyExported: boolean): string => {

    let color: string;
    if (connectedToHoveredNode !== undefined) {
      if (connectedToHoveredNode.has(nodeId)) {
        color = normalColor;
      } else {
        color = desaturate(0.6, lighten(0.25, normalColor));
      }
    } else {
      if (isActive === true) {
        color = isCurrentlyExported ? normalColor : desaturate(0.3, lighten(0.25, normalColor));
      } else {
        color = deselectedCategoryColor;
      }
    }
    return color;
};
