import React from 'react';
const styles = require('./link.css');
import classnames from 'classnames';
import AttrPlugin from 'gsap/AttrPlugin';
import TweenLite from 'gsap/TweenLite';

import {
  darken,
} from 'polished';
import {
  millisecondsPerSeconds,
} from '../../Utils';
import {
  ILink,
} from '../../workerStore/rings/Utils';
import {
  defaultLinkStrokeColor,
  nodeChangeTransitionDuration,
} from '../Utils';

// Need to do this so that `AttrPlugin` is not dropped by the minifier:
/* tslint:disable-next-line:no-unused-expression */
AttrPlugin;

interface IProps {
  link: ILink;
  hovered: number | undefined;
}

const propsToTweenInfo = ({link, hovered}: IProps) => {
  const {source, target, path} = link;
  let stroke: string;
  if (hovered !== undefined) {
    if (hovered === source.id || hovered === target.id) {
      stroke = darken(0.2, defaultLinkStrokeColor);
    } else {
      stroke = defaultLinkStrokeColor;
    }
  } else {
    stroke = defaultLinkStrokeColor;
  }
  return {
    attr: {
      d: path,
      stroke,
    },
  };
};

export default class extends React.PureComponent<IProps> {
  private el: SVGElement | null = null;
  private rememberEl = (el: SVGElement | null) => this.el = el;

  componentDidMount() {
    if (this.el !== null) {
      TweenLite.set(this.el, propsToTweenInfo(this.props));
    }
  }

  componetDidUpdate(prevProps: IProps) {
    const nextProps = this.props;
    if (nextProps.link.path !== prevProps.link.path ||
        nextProps.hovered !== prevProps.hovered) {
      // We cheat by moving this link to the new location half way through the
      // transition animation:
      TweenLite.set(this.el, {
        ...propsToTweenInfo(nextProps), delay: nodeChangeTransitionDuration / 2 / millisecondsPerSeconds,
      });
    }
  }

  render() {
    const className = classnames(styles.connection);
    return (
      <path className={className} ref={this.rememberEl}/>
    );
  }
}
