import React from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import styled from 'styled-components';
import {
  sendHeroElementTiming,
} from '../../heroElement';
import Fade from '../../sharedComponents/DeclarativeFade';
import {
  ILink,
  INode,
  LayoutText,
} from '../../workerStore/rings/Utils';
import {
  svgBackgroundColor,
} from '../Utils';
import {
  nodeChangeTransitionDuration,
} from '../Utils';
import Link from './Link';
import Node from './Node';
const {
  heroElementNames,
} = require('../../../buildConstants');

const Root = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${svgBackgroundColor};
  outline: 1px solid white;
  contain: content;
`;

interface IProps {
  nodes: INode[];
  links: ILink[];
  texts: LayoutText[];
  connectionsMap: Record<string, number[]>;
  hovered: number | undefined;
  chartContainerHeight: number | undefined;
  chartContainerWidth: number | undefined;
  saveRootEl: (el: HTMLElement | null) => void;
  onNodeClick: (id: number) => void;
  onMouseEnter: (id: number) => void;
  onMouseLeave: () => void;
}

export default class extends React.PureComponent<IProps> {
  private rememberRootEl = (el: HTMLElement | null) => this.props.saveRootEl(el);
  private reportHeroElementTimingIfNeeded(props: IProps) {
    if (props.chartContainerHeight !== undefined && props.chartContainerWidth !== undefined) {
      sendHeroElementTiming(heroElementNames.rings);
    }
  }

  componentDidMount() {
    this.reportHeroElementTimingIfNeeded(this.props);
  }

  componentDidUpdate(prevProps: IProps) {
    const nextProps = this.props;
    if (nextProps !== prevProps) {
      this.reportHeroElementTimingIfNeeded(nextProps);
    }
  }

  render() {
    const props = this.props;
    const {
      nodes, links, hovered, connectionsMap,
      chartContainerHeight, chartContainerWidth,
      onNodeClick, onMouseEnter, onMouseLeave,
    } = props;

    if (chartContainerHeight === undefined || chartContainerWidth === undefined) {
      return (
        <Root ref={this.rememberRootEl}/>
      );
    } else {
      let connectedToHoveredNode: Set<number> | undefined;
      if (hovered === undefined) {
        connectedToHoveredNode = undefined;
      } else {
        // FAde out nodes that are neither being hovered the hovered-over node
        // or the hovered-overn node itself:

        const retrieved = connectionsMap[hovered];
        const undefinedChecked = (retrieved === undefined) ? [] : retrieved;
        connectedToHoveredNode = new Set([...undefinedChecked, hovered]);
      }
      const nodeElems = nodes.map(node => (
        <Fade timeout={nodeChangeTransitionDuration} key={node.id}>
          <Node
            node={node} onNodeClick={onNodeClick}
            connectedToHoveredNode={connectedToHoveredNode}
            onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
          />
        </Fade>
      ));

      const normalLinkElems = links.map(link => (
        <Link link={link} hovered={hovered}  key={`normal-link-${link.source.id}-${link.target.id}`}/>
      ));

      // Add the links that need to be highlighted (when hover is happening) so
      // that the unhighlighted link don't cut above the highlighted ones:
      let hoveredLinkElems: JSX.Element[];
      if (hovered === undefined) {
        hoveredLinkElems = [];
      } else {
        const hoveredLinks = links.filter(
          ({source, target}) => (hovered === source.id || hovered === target.id),
        );
        hoveredLinkElems = hoveredLinks.map(link => (
          <Link link={link} hovered={hovered}  key={`hovered-link-${link.source.id}-${link.target.id}`}/>
        ));
      }

      const centeringTransform = `translate(${chartContainerWidth / 2} ${chartContainerHeight / 2})`;

      return (
        <Root ref={this.rememberRootEl}>
          <svg width={chartContainerWidth} height={chartContainerHeight}>
            <g transform={centeringTransform}>
                {normalLinkElems}
            </g>
            <g transform={centeringTransform}>
                {hoveredLinkElems}
            </g>
            <g transform={centeringTransform}>
              <TransitionGroup component='g'>
                {nodeElems}
              </TransitionGroup>
            </g>
          </svg>
        </Root>
      );
    }

  }
}
